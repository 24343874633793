import React, { useState, useEffect } from 'react';
import useTableOrder from 'hooks/tableOrder';

import NoData from 'components/no-data/NoData';
import { StockBalanceData } from 'types/reports/stockBalance';
import ProductList from '../product/table/ProductList';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import InsideLoading from 'components/loading/InsideLoading';
import { useStockBalance } from '../hooks/useStockBalance';

import { makeStyles } from '@mui/styles';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

interface BranchMoreMenuProps {
  branchId: string;
  stoppedStock: boolean;
}

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: 0,
      minHeight: 0,
      overflow: 'visible',
    },
  },
});

const BranchMoreMenu: React.FC<BranchMoreMenuProps> = ({ branchId, stoppedStock }) => {
  const { h2iApi } = useApp();
  const classes = useStyles();
  const { filter } = useStockBalance();
  const [loading, setLoading] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<StockBalanceData[]>([]);

  function handleSort(index: string) {
    const filteredS = sort(index, filtered);
    setFiltered(filteredS);
  }

  useEffect(() => {
    if (!h2iApi) {
      return;
    }

    setLoading(true);

    h2iApi
      .get(`/api/saldoestoque`, {
        params: {
          id_filial: branchId,
          id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
          filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
          id_categoria: filter.categoryId.join(',') || '',
          id_subcategoria: filter.subcategoryId.join(',') || '',
          id_segmento: filter.segmentId.join(',') || '',
          id_unidade: filter.unityId.join(',') || '',
          sem_movimentacao: stoppedStock ? 'S' : '',
          id_fabricante: filter.manufacturerId.join(','),
          id_subgrupo: filter.subgroupId.join(','),
          responsavel: filter.made_by,
        },
      })
      .then(response => {
        if (response.data[0].result) {
          setFiltered([]);
          return;
        }

        const _data = response.data.map((item: StockBalanceData) => {
          item.formattedEstoque = numberFormat(item.Estoque);
          item.formattedCusto = moneyFormat(item.Custo);
          item.formattedCustoTotal = moneyFormat(item.CustoTotal);
          item.formattedValueReserve = moneyFormat(item.valor_reserva);
          item.formattedValueOrder = moneyFormat(item.valor_pedido);
          item.formattedIndex = numberFormat(item.indice);
          item.formattedLastSale = parsePtBRDate(item.ultima_venda).toISOString();
          item.formattedLastEntry = parsePtBRDate(item.ultima_entrada).toISOString();
          return item;
        });
        setFiltered(_data);
      })
      .finally(() => setLoading(false));
  }, [h2iApi, filter, branchId, stoppedStock]);

  return (
    <div className={classes.div}>
      <PaginationProvider>
        {loading ? (
          <InsideLoading />
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum produto encontrado." />
        ) : (
          <ProductList isSubgroup={true} orderedIndex={orderedIndex} products={filtered} handleSort={handleSort} />
        )}
        <Pagination count={filtered.length} />
      </PaginationProvider>
    </div>
  );
};

export default BranchMoreMenu;
