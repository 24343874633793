import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import Pagination from 'components/pagination/Pagination';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { useSelector } from 'store/selector';
import {
  BranchStockBalanceData,
  ManufacturerStockBalanceData,
  ResponsibleStockBalanceData,
  SegmentStockBalanceData,
  StockBalanceData,
  SubgroupStockBalanceData,
  UnityStockBalanceData,
} from 'types/reports/stockBalance';
import ProductListTable from './list/product/table/ProductList';
import ProductListModule from './list/product/module/ProductList';
import StockBalanceReportData from './StockBalanceReportData';
import StockBalanceFilter from '../StockBalanceFilter';
import ResponsibleListTable from './list/responsible/table/ResponsibleListTable';
import ResponsibleListModule from './list/responsible/module/ResponsibleListModule';
import { StockBalanceProvider, StockBalanceTotalProps } from './list/hooks/useStockBalance';
import SubgroupListTable from './list/subgroup/table/SubgroupListTable';
import SubgroupListModule from './list/subgroup/module/SubgroupListModule';
import BranchListModule from './list/branch/module/BranchListModule';
import BranchListTable from './list/branch/table/BranchListTable';
import StockMoreFilter from '../StockMoreFilter';
import UnityListTable from './list/unity/table/UnityListTable';
import UnityListModule from './list/unity/module/UnityListModule';
import SegmentListTable from './list/segment/table/SegmentListTable';
import SegmentListModule from './list/segment/module/SegmentListModule';
import Appbar from 'components/appbar/Appbar';
import { StockFilter } from 'types/reports/stockFilter';
import StockBalanceTotal from '../StockBalanceTotal';
import StockBalanceExport from '../StockBalanceExport';
import ManufacturerListTable from './list/manufacturer/table/ManufacturerListTable';
import StockBalanceTabs from './tab/StockBalanceTabs';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

const Container = styled('div')({
  zoom: '90%',
  display: 'flex',
  flexDirection: 'column',
});

const StockBalance: React.FC = () => {
  const { h2iApi, handleOpenMenu, isOpenedMenu } = useApp();
  const [tab, setTab] = useState('branch');
  const [dataItems, setDataItems] = useState<StockBalanceData[]>([]);
  const [dataSubgroups, setDataSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [dataBranches, setDataBranches] = useState<BranchStockBalanceData[]>([]);
  const [dataResponsibles, setDataResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const branches = useSelector(state => state.branches);
  const [excelExport, setExcelExport] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [filteredResponsibles, setFilteredResponsibles] = useState<ResponsibleStockBalanceData[]>([]);
  const [filteredItems, setFilteredItems] = useState<StockBalanceData[]>([]);
  const [filteredSubgroups, setFilteredSubgroups] = useState<SubgroupStockBalanceData[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<BranchStockBalanceData[]>([]);
  const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreResponsible, setMoreResponsible] = useState<ResponsibleStockBalanceData | null>(null);
  const [moreBranch, setMoreBranch] = useState<BranchStockBalanceData | null>(null);
  const [moreUnity, setMoreUnity] = useState<UnityStockBalanceData | null>(null);
  const [dataUnits, setDataUnits] = useState<UnityStockBalanceData[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<UnityStockBalanceData[]>([]);
  const [moreSegment, setMoreSegment] = useState<SegmentStockBalanceData | null>(null);
  const [dataSegments, setDataSegments] = useState<SegmentStockBalanceData[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<SegmentStockBalanceData[]>([]);
  const [moreStoppedStock, setMoreStoppedStock] = useState<BranchStockBalanceData | null>(null);
  const [dataStoppedStocks, setDataStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [filteredStoppedStocks, setFilteredStoppedStocks] = useState<BranchStockBalanceData[]>([]);
  const [moreManufacturer, setMoreManufacturer] = useState<ManufacturerStockBalanceData | null>(null);
  const [dataManufacturers, setDataManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<ManufacturerStockBalanceData[]>([]);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [orderedIndex, sort] = useTableOrder();
  const [shownReport, setShownReport] = useState(false);
  const [loadingResponsibles, setLoadingResponsibles] = useState(false);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingManufacturers, setLoadingManufacturers] = useState(false);
  const [loadingStoppedStock, setLoadingStoppedStock] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [filter, setFilter] = useState<StockFilter>({
    branchId: [],
    manufacturerId: [],
    subgroupId: [],
    indexToSearch: 'Produto',
    searchValue: '',
    categoryId: [],
    segmentId: [],
    subcategoryId: [],
    unityId: [],
    made_by: '',
  });
  const [formattedTotal, setFormattedTotal] = useState<StockBalanceTotalProps>({
    coustTotal: 0,
    coustStoppedStock: 0,
    order: 0,
    reserve: 0,
    stock: 0,
    stoppedStock: 0,
    forecast: 0,
    index: 0,
  });

  const selectedBranches = useMemo(() => {
    const branch = branches.filter(branch => filter.branchId.some(item => item === branch.id));
    return branch;
  }, [branches, filter.branchId]);

  const handleSearch = useCallback(
    async (filter: StockFilter, isOpenedMenu?: boolean, handleOpenMenu?: () => void) => {
      if (!h2iApi) {
        return;
      }

      if (isOpenedMenu && handleOpenMenu) {
        handleOpenMenu();
      }

      setLoadingResponsibles(true);
      setLoadingSubgroups(true);
      setLoadingUnits(true);
      setLoadingSegments(true);
      setLoadingItems(true);
      setLoadingManufacturers(true);
      setLoadingStoppedStock(true);
      setLoadingBranches(true);

      const fetchResponsibles = h2iApi
        ?.get(`/api/relestresponsavel`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredResponsibles([]);
            return;
          }
          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            return item;
          });
          setDataResponsibles(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingResponsibles(false));

      const fetchSubgroups = h2iApi
        ?.get(`/api/relestsubgrupo`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque.result) {
            setFilteredSubgroups([]);
            return;
          }

          const _data = response.data.estoque.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSubgroups(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSubgroups(false));

      const fetchUnits = h2iApi
        ?.get(`/api/relestunidade`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataUnits(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingUnits(false));

      const fetchSegments = h2iApi
        ?.get(`/api/relestsegmento`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.segmento.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            return item;
          });
          setDataSegments(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingSegments(false));

      const fetchItems = h2iApi
        .get(`/api/saldoestoque`, {
          params: {
            id_filial: filter.branchId.join(','),
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(','),
            id_subcategoria: filter.subcategoryId.join(','),
            id_segmento: filter.segmentId.join(','),
            id_unidade: filter.unityId.join(','),
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data[0].result) {
            setFilteredItems([]);
            setFormattedTotal(item => ({
              ...item,
              coustTotal: 0,
              index: 0,
            }));
            return;
          }

          const coustTotal = response.data.reduce((sum, item) => sum + item.CustoTotal, 0);
          const index = response.data.reduce((sum, item) => sum + item.indice, 0);
          setFormattedTotal(item => ({
            ...item,
            coustTotal,
            index,
          }));

          const _data: StockBalanceData[] = response.data.map((item: StockBalanceData) => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedIndex = numberFormat(item.indice);
            return item;
          });

          const forecast = response.data.reduce((sum, item) => sum + item.forecast, 0);
          setFormattedTotal(item => ({
            ...item,
            forecast,
          }));
          setDataItems(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingItems(false));

      const fetchManufacturers = h2iApi
        .get(`/api/relestfabricante`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          const _data = response.data.filiais.map(item => {
            item.formattedEstoque = numberFormat(item.Estoque);
            item.formattedCusto = moneyFormat(item.Custo);
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = parsePtBRDate(item.ultima_venda).toISOString();
            item.formattedLastEntry = parsePtBRDate(item.ultima_entrada).toISOString();
            return item;
          });
          setDataManufacturers(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingManufacturers(false));

      const fetchBranchesStoppedStock = h2iApi
        .get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            sem_movimentacao: 'S',
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setFilteredStoppedStocks([]);
            setFormattedTotal(item => ({
              ...item,
              coustStoppedStock: 0,
              stoppedStock: 0,
            }));
            return;
          }

          const coustStoppedStock = response.data.filiais.reduce((sum, item) => sum + item.CustoTotal, 0);
          const stoppedStock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);
          setFormattedTotal(item => ({
            ...item,
            coustStoppedStock,
            stoppedStock,
          }));

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = parsePtBRDate(item.ultima_venda).toISOString();
            item.formattedLastEntry = parsePtBRDate(item.ultima_entrada).toISOString();
            return item;
          });
          setDataStoppedStocks(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingStoppedStock(false));

      const fetchBranches = h2iApi
        ?.get(`/api/relestfilial`, {
          params: {
            id_filial: filter.branchId.join(',') || '',
            id_produto: filter.indexToSearch === 'CodProd' ? filter.searchValue : '',
            filtro: filter.indexToSearch === 'Produto' ? filter.searchValue.replace(/ /g, '%') : '',
            id_categoria: filter.categoryId.join(',') || '',
            id_subcategoria: filter.subcategoryId.join(',') || '',
            id_segmento: filter.segmentId.join(',') || '',
            id_unidade: filter.unityId.join(',') || '',
            id_fabricante: filter.manufacturerId.join(','),
            id_subgrupo: filter.subgroupId.join(','),
            responsavel: filter.made_by,
          },
        })
        .then(response => {
          if (response.data.estoque) {
            setDataBranches([]);
            setFormattedTotal(item => ({
              ...item,
              order: 0,
              reserve: 0,
              stock: 0,
            }));
            return;
          }

          const _data = response.data.filiais.map(item => {
            item.formattedCustoTotal = moneyFormat(item.CustoTotal);
            item.formattedValueReserve = moneyFormat(item.valor_reserva);
            item.formattedValueOrder = moneyFormat(item.valor_pedido);
            item.formattedLastSale = parsePtBRDate(item.ultima_venda).toISOString();
            item.formattedLastEntry = parsePtBRDate(item.ultima_entrada).toISOString();
            return item;
          });

          const reserve = response.data.filiais.reduce((sum, item) => sum + item.valor_reserva, 0);
          const order = response.data.filiais.reduce((sum, item) => sum + item.valor_pedido, 0);
          const stock = response.data.filiais.reduce((sum, item) => sum + item.EstTotal, 0);

          setFormattedTotal(item => ({
            ...item,
            order,
            reserve,
            stock,
          }));

          setDataBranches(_data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingBranches(false));

      Promise.all([
        fetchResponsibles,
        fetchSubgroups,
        fetchUnits,
        fetchSegments,
        fetchItems,
        fetchManufacturers,
        fetchBranchesStoppedStock,
        fetchBranches,
      ]);
    },
    [h2iApi],
  );

  function handleSortManufacturer(index: string) {
    const p = sort(index, filteredManufacturers);
    setFilteredManufacturers(p);
  }

  function handleSortResponsible(index: string) {
    const p = sort(index, filteredResponsibles);
    setFilteredResponsibles(p);
  }

  function handleSortBranch(index: string) {
    const p = sort(index, dataBranches);
    setFilteredBranches(p);
  }

  function handleSortStoppedStock(index: string) {
    const p = sort(index, dataStoppedStocks);
    setFilteredStoppedStocks(p);
  }

  function handleSortSubgroup(index: string) {
    const p = sort(index, filteredSubgroups);
    setFilteredSubgroups(p);
  }

  function handleSortUnity(index: string) {
    const p = sort(index, filteredUnits);
    setFilteredUnits(p);
  }

  function handleSortSegment(index: string) {
    const p = sort(index, filteredSegments);
    setFilteredSegments(p);
  }

  function handleSortItem(index: string) {
    const p = sort(index, filteredItems);
    setFilteredItems(p);
  }

  function handleChange(index: keyof StockFilter, value: any) {
    setFilter(state => ({
      ...state,
      [index]: value,
    }));
  }

  useEffect(() => {
    setFilteredItems(dataItems);
  }, [dataItems]);

  useEffect(() => {
    setFilteredBranches(dataBranches);
  }, [dataBranches]);

  useEffect(() => {
    setFilteredStoppedStocks(dataStoppedStocks);
  }, [dataStoppedStocks]);

  useEffect(() => {
    setFilteredManufacturers(dataManufacturers);
  }, [dataManufacturers]);

  useEffect(() => {
    setFilteredResponsibles(dataResponsibles);
  }, [dataResponsibles]);

  useEffect(() => {
    setFilteredSubgroups(dataSubgroups);
  }, [dataSubgroups]);

  useEffect(() => {
    setFilteredUnits(dataUnits);
  }, [dataUnits]);

  useEffect(() => {
    setFilteredSegments(dataSegments);
  }, [dataSegments]);

  return (
    <StockBalanceProvider
      value={{
        moreSegment,
        setMoreSegment,
        moreUnity,
        setMoreUnity,
        moreBranch,
        setMoreBranch,
        moreStoppedStock,
        setMoreStoppedStock,
        moreResponsible,
        setMoreResponsible,
        moreManufacturer,
        setMoreManufacturer,
        setSelectedResponsible,
        selectedResponsible,
        filter,
      }}
    >
      {moreFilter && (
        <StockMoreFilter
          filter={filter}
          onSearch={() => handleSearch(filter)}
          onExited={() => setMoreFilter(false)}
          handleChangeFilter={handleChange}
        />
      )}

      {shownReport && (
        <StockBalanceReportData
          data={filteredItems}
          branches={selectedBranches}
          onExited={() => setShownReport(false)}
        />
      )}

      <Appbar title="Saldo de Estoque" />

      <StockBalanceFilter
        filter={filter}
        setExcelExport={setExcelExport}
        openMoreFilter={() => setMoreFilter(true)}
        openReport={() => setShownReport(true)}
        displayMode={displayMode}
        handleChange={handleChange}
        onSearch={filter => handleSearch(filter, isOpenedMenu, handleOpenMenu)}
        setDisplayMode={setDisplayMode}
      />

      <StockBalanceTabs tab={tab} onChangeTab={tab => setTab(tab)} />

      <StockBalanceTotal loading={loadingItems} formattedTotal={formattedTotal} />

      {excelExport && <StockBalanceExport filter={filter} onExited={() => setExcelExport(false)} />}

      {tab === 'responsible' ? (
        loadingResponsibles ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredResponsibles.length === 0 ? (
          <NoData message="Nenhum responsável encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ResponsibleListTable
                  isSubgroup
                  responsibles={filteredResponsibles}
                  handleSort={handleSortResponsible}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <ResponsibleListModule responsibles={filteredResponsibles} />
              )}
              <Pagination count={filteredResponsibles.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'branch' ? (
        loadingBranches ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredBranches.length === 0 ? (
          <NoData message="Nenhuma filial encontrada." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <BranchListTable
                  stoppedStock={false}
                  isSubgroup
                  branches={filteredBranches}
                  handleSort={handleSortBranch}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <BranchListModule branches={filteredBranches} />
              )}
              <Pagination count={filteredBranches.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'unity' ? (
        loadingUnits ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredUnits.length === 0 ? (
          <NoData message="Nenhuma unidade encontrada." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <UnityListTable units={filteredUnits} handleSort={handleSortUnity} orderedIndex={orderedIndex} />
              ) : (
                <UnityListModule units={filteredUnits} />
              )}
              <Pagination count={filteredUnits.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'segment' ? (
        loadingSegments ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredSegments.length === 0 ? (
          <NoData message="Nenhum segmento encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SegmentListTable
                  segments={filteredSegments}
                  handleSort={handleSortSegment}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SegmentListModule segments={filteredSegments} />
              )}
              <Pagination count={filteredSegments.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'subgroup' ? (
        loadingSubgroups ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredSubgroups.length === 0 ? (
          <NoData message="Nenhum subgrupo encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <SubgroupListTable
                  subgroups={filteredSubgroups}
                  handleSort={handleSortSubgroup}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <SubgroupListModule subgroups={filteredSubgroups} />
              )}
              <Pagination count={filteredSubgroups.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'items' ? (
        loadingItems ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredItems.length === 0 ? (
          <NoData message="Nenhum item encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ProductListTable
                  isSubgroup={false}
                  products={filteredItems}
                  handleSort={handleSortItem}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <ProductListModule products={filteredItems} />
              )}
              <Pagination count={filteredItems.length} />
            </Container>
          </PaginationProvider>
        )
      ) : tab === 'manufacturer' ? (
        loadingManufacturers ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filteredManufacturers.length === 0 ? (
          <NoData message="Nenhum fabricante encontrado." />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <ManufacturerListTable
                  manufacturers={filteredManufacturers}
                  handleSort={handleSortManufacturer}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <>{/* <ManufacturerListModule products={filteredManufacturers} /> */}</>
              )}
              <Pagination count={filteredManufacturers.length} />
            </Container>
          </PaginationProvider>
        )
      ) : loadingStoppedStock ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filteredStoppedStocks.length === 0 ? (
        <NoData message="Nenhum estoque sem movimentação encontrado." />
      ) : (
        <PaginationProvider>
          <Container>
            {displayMode === 'list' ? (
              <BranchListTable
                stoppedStock
                isSubgroup
                branches={filteredStoppedStocks}
                handleSort={handleSortStoppedStock}
                orderedIndex={orderedIndex}
              />
            ) : (
              <BranchListModule branches={filteredStoppedStocks} />
            )}
            <Pagination count={filteredStoppedStocks.length} />
          </Container>
        </PaginationProvider>
      )}
    </StockBalanceProvider>
  );
};

export default StockBalance;
