import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import React, { FormEvent } from 'react';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import { H2iBuyer } from 'types/h2iBuyer';
import { FilterRmaProps } from './hooks/useFetchRma';
import { Search } from '@mui/icons-material';

interface RmaFilterBoxProps {
  buyers: H2iBuyer[];
  loadingBuyers: boolean;
  filter: FilterRmaProps;
  onSearch(filter: FilterRmaProps): void;
  handleChangeFilter(index: keyof FilterRmaProps, value: any);
  loading: boolean;
}

const Filter = styled('form')(({ theme }) => ({
  gap: 15,
  zoom: '80%',
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '200px 400px 140px 170px 170px 1fr 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 2fr 1fr 1fr 170px 1fr 100px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1.4fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
}));

const RmaFilterBox: React.FC<RmaFilterBoxProps> = ({
  buyers,
  loadingBuyers,
  handleChangeFilter,
  filter,
  onSearch,
  loading,
}) => {
  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch handleChange={value => handleChangeFilter('branch_id', value)} branch_id={filter.branch_id} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          select
          label="Situação"
          value={filter.situation}
          onChange={e => handleChangeFilter('situation', e.target.value)}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="B">Bom</MenuItem>
          <MenuItem value="R">Ruim</MenuItem>
          <MenuItem value="S">Sem Laudo</MenuItem>
        </TextField>

        <TextField
          label="Selecione o comprador"
          select
          disabled={loadingBuyers}
          onChange={e => handleChangeFilter('buyer', e.target.value)}
          value={filter.buyer}
        >
          <MenuItem value="all">Todos</MenuItem>
          {buyers.map(buyer => (
            <MenuItem key={buyer.id} value={buyer.id}>
              {buyer.nome}
            </MenuItem>
          ))}
        </TextField>

        <TextField label="Tipo" select onChange={e => handleChangeFilter('type', e.target.value)} value={filter.type}>
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="C">Cliente</MenuItem>
          <MenuItem value="N">Netsolution</MenuItem>
        </TextField>

        <TextField
          placeholder="Digite o nome ou código do produto"
          label="Produto/Código"
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />

        <Button startIcon={<Search />} size="small" disabled={loading} variant="contained" type="submit">
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default RmaFilterBox;
