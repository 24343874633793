import React from 'react';
import { Typography, darken, styled } from '@mui/material';
import { SalesReportTotal } from './SalesReport';
import { moneyFormat, numberFormat } from 'helpers/numberFormat';
import { CityMethod, SalesMethod } from 'types/salesReport';
import { LineAxis } from '@mui/icons-material';

const Container = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  marginBottom: 10,
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

const Box = styled('div')(({ theme }) => ({
  border: '1px solid #eee',
  backgroundColor: '#fff',
  flexDirection: 'column',
  display: 'flex',
  '&.span2': {
    gridColumn: '1/3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '3/4',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1/3',
    },
  },
  '&.mc': {
    [theme.breakpoints.down('sm')]: {
      gridRow: '3/4',
      gridColumn: '2/3',
    },
  },
  '& .title': {
    background: '#f1f7ef',
    fontSize: 12,
    borderBottom: '1px solid #f5f5f5',
    padding: '8px 20px',
  },
  '& .first': {
    background: theme.palette.grey[400],
    color: darken(theme.palette.grey[400], 0.5),
  },
  '& .second': {
    background: '#fdf2cd',
    color: darken('#fdf2cd', 0.5),
  },
  '& .third': {
    background: '#eeeff8',
    color: darken('#eeeff8', 0.5),
  },
  '& .fourth': {
    background: '#f8eef3',
    color: darken('#f8eef3', 0.5),
  },
  '& .fifth': {
    background: '#e3e3e3',
    color: darken('#e3e3e3', 0.5),
  },
  '& .sixth': {
    background: '#ffc8c8',
    color: darken('#ffc8c8', 0.5),
  },
  '& .seventh': {
    background: '#898fbd',
    color: darken('#898fbd', 0.5),
  },
  '& .eighth': {
    background: '#d0f7f7',
    color: darken('#d0f7f7', 0.5),
  },
  '& .ninth': {
    background: '#bce9d6',
    color: darken('#bce9d6', 0.5),
  },
  '& .main': {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontWeight: 600,
    fontSize: 14,
  },
}));

interface SalesReportTotalProps {
  formattedTotal: SalesReportTotal;
  salesMethods: SalesMethod[];
  citiesMethods: CityMethod[];
}

const SalesReportTotalPage: React.FC<SalesReportTotalProps> = ({ formattedTotal, salesMethods, citiesMethods }) => {
  return (
    <Container>
      <Box>
        <Typography className="title first" variant="body2" fontSize={18}>
          Valor total
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.total}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title second" variant="body2" fontSize={18}>
          Qtd de vendas
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {salesMethods.reduce((sum, item) => sum + item.qtd_vendas, 0)}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title third" variant="body2" fontSize={18}>
          Ticket médio
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {numberFormat(
              salesMethods.reduce((sum, item) => sum + item.valor, 0) /
                salesMethods.reduce((sum, item) => sum + item.qtd_vendas, 0),
            )}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fourth" variant="body2" fontSize={18}>
          Total PJ
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.pj}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title fifth" variant="body2" fontSize={18}>
          Total PF
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.pf}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box className="span2">
        <Typography className="title seventh" variant="body2" fontSize={18}>
          Balcão - Cliente na Loja
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>
            <Typography className="value" variant="body2">
              {salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja').length > 0
                ? salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja')[0].qtd_vendas
                : 0}
            </Typography>
          </div>

          <div>
            <Typography variant="caption" fontSize={11}>
              Valor
            </Typography>
            <Typography className="value" variant="body2">
              {salesMethods.length > 0 &&
              salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja').length > 0
                ? salesMethods.filter(sale => sale.modalidade === 'Balcão - Cliente na Loja')[0].formattedValue
                : 'R$ 0,00'}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title sixth" variant="body2" fontSize={18}>
          Demais modalidade
        </Typography>
        <div style={{ padding: '5px 15px' }} className="main">
          <div>
            <Typography variant="caption" fontSize={11}>
              Qtd
            </Typography>
            <Typography className="value" variant="body2">
              {salesMethods.filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja').length > 0
                ? salesMethods
                    .filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja')
                    .reduce((sum, item) => sum + item.qtd_vendas, 0)
                : 0}
            </Typography>
          </div>

          <div>
            <Typography variant="caption" fontSize={11}>
              Valor
            </Typography>
            <Typography className="value" variant="body2">
              {moneyFormat(
                salesMethods
                  .filter(sale => sale.modalidade !== 'Balcão - Cliente na Loja')
                  .reduce((sum, item) => sum + item.valor, 0),
              )}
            </Typography>
          </div>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box className="mc">
        <Typography className="title eighth" variant="body2" fontSize={18}>
          MC
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {formattedTotal.mc}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>

      <Box>
        <Typography className="title ninth" variant="body2" fontSize={18}>
          Qtd de cidades atendidas
        </Typography>
        <div className="main">
          <Typography className="value" variant="body2">
            {citiesMethods.length}
          </Typography>
          <LineAxis color="secondary" />
        </div>
      </Box>
    </Container>
  );
};

export default SalesReportTotalPage;
