import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import PageHeader from 'components/page-header/PageHeader';
import AnalysisReplacementTabs from './tab/AnalysisReplacementTabs';
import { useFetchReplacement } from './hooks/useFetchReplacement';
import { Replacement } from 'types/replacement';
import AnalysisReplacementFilterBox from './AnalysisReplacementFilterBox';
import AnalysisReplacementDashboard from './dashboard/AnalysisReplacementDashboard';
import AnalysisReplacementDashboardProduct from './dashboard/product/AnalysisReplacementDashboardProduct';
import { useFetchAnalysisReplacementDashboard } from './dashboard/hooks/useFetchAnalysisReplacementDashboard';
import CommercialAnalysisProductReserve from 'pages/bi/analysis-product/reserve/CommercialAnalysisProductReserve';
import { useFetchAnalysisReplacementSubgroup } from './subgroup/hooks/useFetchAnalysisReplacementSubgroup';
import AnalysisReplacementSubgroup from './subgroup/AnalysisReplacementSubgroup';
import AnalysisReplacementBranch from './branch/AnalysisReplacementBranch';
import { useFetchAnalysisReplacementBranch } from './branch/hooks/useFetchAnalysisReplacementBranch';
import useTableOrder from 'hooks/tableOrder';

const AnalysisReplacement: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const [orderedIndex, sort] = useTableOrder();
  const [subgroupsOpen, setSubgroupsOpen] = useState<number[]>([]);
  const [branchesSurplusStockOpen, setBranchesSurplusStockOpen] = useState<number[]>([]);
  const [branchesOpen, setBranchesOpen] = useState<number[]>([]);
  const [tab, setTab] = useState<'replacements' | 'dashboard' | string>('dashboard');
  const {
    loading,
    replacements,
    filter,
    buyers,
    subgroups,
    reserves,
    loadingSubgroups,
    loadingBuyers,
    handleChangeFilter,
    onSearch,
  } = useFetchReplacement();
  const [filtered, setFiltered] = useState<Replacement[]>([]);
  const { groupedProducts } = useFetchAnalysisReplacementDashboard(replacements);
  const { filteredSubgroups, handleSortSubgroup } = useFetchAnalysisReplacementSubgroup(replacements);
  const { filteredBranches, handleSortBranch, filteredBranchesSurplusStock, handleSortBranchSurplusStock } =
    useFetchAnalysisReplacementBranch(replacements);

  useEffect(() => {
    setFiltered(replacements);
  }, [replacements]);

  function onClickButton(index: number) {
    filteredSubgroups[index].visto = true;
    if (subgroupsOpen.includes(index)) {
      setSubgroupsOpen(subgroupsOpen.filter(i => i !== index));
    } else {
      setSubgroupsOpen([...subgroupsOpen, index]);
    }
  }

  function onClickButtonBranch(index: number) {
    filteredBranches[index].visto = true;
    if (branchesOpen.includes(index)) {
      setBranchesOpen(branchesOpen.filter(i => i !== index));
    } else {
      setBranchesOpen([...branchesOpen, index]);
    }
  }

  function onClickButtonBranchSurplusStock(index: number) {
    filteredBranchesSurplusStock[index].visto = true;
    if (branchesSurplusStockOpen.includes(index)) {
      setBranchesSurplusStockOpen(branchesSurplusStockOpen.filter(i => i !== index));
    } else {
      setBranchesSurplusStockOpen([...branchesSurplusStockOpen, index]);
    }
  }

  function handleSort(index: string) {
    const filtered = sort(index, replacements);
    setFiltered(filtered);
  }

  return (
    <>
      <Appbar title="Reposição" />
      <PageHeader title="Reposição" />

      <AnalysisReplacementFilterBox
        buyers={buyers}
        loadingBuyers={loadingBuyers}
        filter={filter}
        loadingSubgroups={loadingSubgroups}
        subgroups={subgroups}
        handleChangeFilter={handleChangeFilter}
        loading={loading}
        onSearch={onSearch}
      />

      <AnalysisReplacementTabs tab={tab} handleChange={value => setTab(value)} />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum item para mostrar" />
      ) : tab === 'replacements' ? (
        <AnalysisReplacementBranch
          handleSortReplacements={handleSort}
          orderedIndex={orderedIndex}
          replacements={filtered}
          branchesOpen={branchesOpen}
          filteredBranches={filteredBranches}
          handleSort={handleSortBranch}
          onClickButton={onClickButtonBranch}
        />
      ) : tab === 'dashboard' ? (
        <AnalysisReplacementDashboard replacements={replacements} />
      ) : tab === 'reserve' ? (
        <CommercialAnalysisProductReserve reserves={reserves} />
      ) : tab === 'product' ? (
        <PaginationProvider>
          <AnalysisReplacementDashboardProduct products={groupedProducts} />
        </PaginationProvider>
      ) : tab === 'subgroup' ? (
        <PaginationProvider>
          <AnalysisReplacementSubgroup
            filteredSubgroups={filteredSubgroups}
            handleSort={handleSortSubgroup}
            onClickButton={onClickButton}
            subgroupsOpen={subgroupsOpen}
            replacements={replacements}
          />
        </PaginationProvider>
      ) : (
        <AnalysisReplacementBranch
          handleSortReplacements={handleSort}
          orderedIndex={orderedIndex}
          replacements={filtered}
          branchesOpen={branchesSurplusStockOpen}
          filteredBranches={filteredBranchesSurplusStock}
          handleSort={handleSortBranchSurplusStock}
          onClickButton={onClickButtonBranchSurplusStock}
        />
      )}
    </>
  );
};

export default AnalysisReplacement;
