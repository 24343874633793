import React, { useEffect, useRef } from 'react';
import { QueryParams } from './ExportEmail';
import { useExportEmail } from './hooks/useExportEmail';
import FilterBox from 'components/filter-box/FilterBox';
import { MenuItem, styled, TextField } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import SelectBranch from 'components/select-branches/SelectBranch';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '190px 200px 1fr 1fr 1fr',
  gap: 10,
  flex: 1,
  zoom: '90%',
  [theme.breakpoints.down('lg')]: {
    zoom: '80%',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  '& > .search-params': {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: '300px 300px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

type ExportEmailFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
};

const ExportEmailFilterBox: React.FC<ExportEmailFilterBoxProps> = ({ queryParams, handleQueryParamsChange }) => {
  const { segments } = useExportEmail();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, []);

  return (
    <FilterBox>
      <Filter>
        <SelectBranch branch_id={queryParams.id_filial} handleChange={e => handleQueryParamsChange('id_filial', e)} />
        <TextField
          select
          autoFocus
          value={queryParams.id_atividade}
          label="Selecione a atividade"
          onChange={e => handleQueryParamsChange('id_atividade', e.target.value)}
        >
          {segments.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
          <MenuItem key="" value="">
            Todas as atividades
          </MenuItem>
        </TextField>
        <TextField
          select
          autoFocus
          value={queryParams.tipo}
          onChange={e => handleQueryParamsChange('tipo', e.target.value)}
          label="Periodo de vendas"
        >
          <MenuItem key="C" value="C">
            Cadastrados sem compras
          </MenuItem>
          <MenuItem key="P" value="P">
            Cadastrados com compras
          </MenuItem>
          <MenuItem key="V" value="V">
            Periodo com vendas
          </MenuItem>
          <MenuItem key="S" value="S">
            Periodo sem vendas
          </MenuItem>
          <MenuItem key="N" value="N">
            Todos os períodos
          </MenuItem>
        </TextField>
        <KeyboardDatePicker
          label="Data inicial"
          value={queryParams.data_ini}
          onChange={date => handleQueryParamsChange('data_ini', date)}
        />
        <KeyboardDatePicker
          label="Data Final"
          value={queryParams.data_fim}
          onChange={date => handleQueryParamsChange('data_fim', date)}
        />
      </Filter>
    </FilterBox>
  );
};

export default ExportEmailFilterBox;
