import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AbcCustomerWithoutSaleFilterParams } from './CustomerWithoutSale';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { ptBR } from 'date-fns/locale';
import { usePermissionRules } from 'hooks/permissionRules';
import { FaFileExcel } from 'react-icons/fa';

const Filter = styled('form')(props => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  zoom: '90%',
  alignItems: 'center',
  gridTemplateColumns: '190px 130px 1fr 0.5fr 0.5fr 180px',
  [props.theme.breakpoints.down('lg')]: {
    zoom: '80%',
  },
  [props.theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr ',
  },
  '& > .search-params': {
    gap: 10,
    display: 'flex',
    alignSelf: 'end',
    [props.theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const CustomFormControl = styled(FormControl)(props => ({
  height: '100%',
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DatePickerContent = styled('div')({
  display: 'flex',
  position: 'relative',
  borderRadius: 4,
  flex: 1,
  '& > .label': {
    position: 'absolute',
    top: -12,
    left: 10,
    fontSize: 11,
  },
});

const DatePickerContainer = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  flex: 1,
  [props.theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '100px 1fr 1fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    '& > button': {
      maxWidth: 100,
      justifySelf: 'end',
    },
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface AbcCustomerFilterBoxProps {
  filter: AbcCustomerWithoutSaleFilterParams;
  handleChangeFilter(index: keyof AbcCustomerWithoutSaleFilterParams, value: any): void;
  handleOpenDialogDesktop(): void;
  searchText: string;
  productText: string;
  handleSearchInputChange(index: 'product' | 'text', value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  handleOpenExcel(): void;
}

const AbcCustomerFilterBox: React.FC<AbcCustomerFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  productText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenExcel,
}) => {
  const { checkPermission } = usePermissionRules();
  function handleChangeDateWithoutSale(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleChangeFilter('withoutSale_initialDate', start);
    handleChangeFilter('withoutSale_finalDate', end);
  }

  function handleChangeDateSale(dates: [Date | null, Date | null]) {
    const [start, end] = dates;
    handleChangeFilter('sale_initialDate', start);
    handleChangeFilter('sale_finalDate', end);
  }

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <CustomFormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </CustomFormControl>

        <div className="search-params">
          <DatePickerContainer>
            <DatePickerContent>
              <span className="label">Periodo sem venda</span>
              <DatePicker
                selected={filter.withoutSale_initialDate}
                onChange={dates => handleChangeDateWithoutSale(dates)}
                startDate={filter.withoutSale_initialDate}
                endDate={filter.withoutSale_finalDate}
                selectsRange
                dateFormat="dd/MM/yyyy"
                locale={ptBR}
              />
            </DatePickerContent>
          </DatePickerContainer>

          <DatePickerContainer>
            <DatePickerContent>
              <span className="label">Periodo com venda</span>
              <DatePicker
                selected={filter.sale_initialDate}
                onChange={dates => handleChangeDateSale(dates)}
                startDate={filter.sale_initialDate}
                endDate={filter.sale_finalDate}
                selectsRange
                locale={ptBR}
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerContent>
          </DatePickerContainer>
        </div>
        <TextField
          label="Produto"
          placeholder="Produto"
          autoFocus
          value={productText}
          onChange={e => handleSearchInputChange('product', e.target.value)}
        />
        <TextField
          label="Cliente"
          placeholder="Cliente"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange('text', e.target.value)}
        />
        <Actions>
          <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
            Buscar
          </Button>

          <Tooltip title="Outros filtros">
            <CustomIconButton onClick={handleOpenDialogDesktop} color="inherit">
              <FilterList />
            </CustomIconButton>
          </Tooltip>

          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <CustomIconButton onClick={handleOpenExcel} style={{ color: 'green' }}>
                <FaFileExcel />
              </CustomIconButton>
            </Tooltip>
          )}
        </Actions>
      </Filter>
    </FilterBox>
  );
};

export default AbcCustomerFilterBox;
