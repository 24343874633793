import React, { useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { usePermissionRules } from 'hooks/permissionRules';

type ReportsTabProps = {
  handleChange(value: string): void;
  tab: string;
};

const Tabs = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  backgroundColor: theme.palette.primary.light,
  left: 0,
  right: 0,
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '& > div': {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const CustomTab = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  position: 'relative',
  color: '#ddd',
  padding: '6px 16px',
  transition: 'all ease 0.3s',
  minWidth: 140,
  '&.active': {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Span = styled('span')({
  position: 'absolute',
  top: '100%',
  display: 'flex',
  width: '100%',
  boxShadow: '1px 1px 9px 1px #000',
  flexDirection: 'column',
  backgroundColor: '#fff',
  zIndex: 35,
});

const MenuItem = styled(Typography)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&.active': {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
  textAlign: 'start',
  padding: 10,
  cursor: 'pointer',
  fontSize: 12,
  transition: 'all ease 0.3s',
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
}));

const ReportsTab: React.FC<ReportsTabProps> = ({ tab, handleChange }) => {
  const { checkPermission } = usePermissionRules();
  const [showSales, setShowSales] = useState(false);
  const [showReserve, setShowReserve] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [showRma, setShowRma] = useState(false);
  const [showCurveAbc, setShowCurveAbc] = useState(false);
  const [showReports, setShowReports] = useState(false);

  return (
    <>
      <Tabs>
        <div>
          {checkPermission('reports_stock') && (
            <CustomTab
              onMouseOver={() => setShowStock(true)}
              onMouseLeave={() => setShowStock(false)}
              onBlur={() => setShowStock(false)}
              className={
                tab === 'stock_balance' || tab === 'item_entry' || tab === 'note_entry' || tab === 'stock_indicator'
                  ? 'active'
                  : ''
              }
            >
              Estoque <ArrowDropDown />
              {showStock && (
                <Span>
                  <MenuItem
                    className={tab === 'stock_balance' ? 'active' : ''}
                    onClick={() => handleChange('stock_balance')}
                  >
                    Saldo de Estoque
                  </MenuItem>
                  <MenuItem
                    className={tab === 'stock_indicator' ? 'active' : ''}
                    onClick={() => handleChange('stock_indicator')}
                  >
                    Indicador de estoque
                  </MenuItem>

                  <MenuItem className={tab === 'item_entry' ? 'active' : ''} onClick={() => handleChange('item_entry')}>
                    Entrada de itens
                  </MenuItem>

                  <MenuItem className={tab === 'note_entry' ? 'active' : ''} onClick={() => handleChange('note_entry')}>
                    Relação de compras
                  </MenuItem>
                </Span>
              )}
            </CustomTab>
          )}

          {checkPermission('reports_curve-abc') && (
            <CustomTab
              onMouseOver={() => setShowCurveAbc(true)}
              onMouseLeave={() => setShowCurveAbc(false)}
              onBlur={() => setShowCurveAbc(false)}
              className={
                tab === 'curve_abc-product' ||
                tab === 'curve_abc-customer' ||
                tab === 'curve_abc-segment' ||
                tab === 'curve_abc-unity' ||
                tab === 'curve_abc-manufacturer'
                  ? 'active'
                  : ''
              }
            >
              Curva Abc <ArrowDropDown />
              {showCurveAbc && (
                <Span>
                  {checkPermission('reports_curve-abc_product') && (
                    <MenuItem
                      className={tab === 'curve_abc-product' ? 'active' : ''}
                      onClick={() => handleChange('curve_abc-product')}
                    >
                      De Produto
                    </MenuItem>
                  )}

                  {checkPermission('reports_curve-abc_customer') && (
                    <MenuItem
                      className={tab === 'curve_abc-customer' ? 'active' : ''}
                      onClick={() => handleChange('curve_abc-customer')}
                    >
                      De Cliente
                    </MenuItem>
                  )}

                  {checkPermission('reports_curve-abc_segment') && (
                    <MenuItem
                      className={tab === 'curve_abc-segment' ? 'active' : ''}
                      onClick={() => handleChange('curve_abc-segment')}
                    >
                      De Segmento
                    </MenuItem>
                  )}

                  {checkPermission('reports_curve-abc_unity') && (
                    <MenuItem
                      className={tab === 'curve_abc-unity' ? 'active' : ''}
                      onClick={() => handleChange('curve_abc-unity')}
                    >
                      De Unidade
                    </MenuItem>
                  )}

                  {checkPermission('reports_curve-abc_manufacturer') && (
                    <MenuItem
                      className={tab === 'curve_abc-manufacturer' ? 'active' : ''}
                      onClick={() => handleChange('curve_abc-manufacturer')}
                    >
                      De Fabricante
                    </MenuItem>
                  )}
                </Span>
              )}
            </CustomTab>
          )}

          {checkPermission('reports_sales') && (
            <CustomTab
              onMouseOver={() => setShowSales(true)}
              onMouseLeave={() => setShowSales(false)}
              onBlur={() => setShowSales(false)}
              className={
                tab === 'sales_app' ||
                tab === 'sales_report' ||
                tab === 'sales_monthly-comparison' ||
                tab === 'sales_compared-product' ||
                tab === 'sales_reprint-sale'
                  ? 'active'
                  : ''
              }
            >
              Vendas <ArrowDropDown />
              {showSales && (
                <Span>
                  {checkPermission('reports_sales_app') && (
                    <MenuItem className={tab === 'sales_app' ? 'active' : ''} onClick={() => handleChange('sales_app')}>
                      Vendas App
                    </MenuItem>
                  )}

                  {checkPermission('reports_sales_itemssold') && (
                    <MenuItem
                      className={tab === 'sales_report' ? 'active' : ''}
                      onClick={() => handleChange('sales_report')}
                    >
                      Relação de vendas
                    </MenuItem>
                  )}

                  <MenuItem
                    className={tab === 'sales_reprint-sale' ? 'active' : ''}
                    onClick={() => handleChange('sales_reprint-sale')}
                  >
                    Reimpressão de venda
                  </MenuItem>

                  {checkPermission('reports_sales_compared-curve-abc-product') && (
                    <MenuItem
                      className={tab === 'sales_compared-product' ? 'active' : ''}
                      onClick={() => handleChange('sales_compared-product')}
                    >
                      Comparativo de produto
                    </MenuItem>
                  )}

                  <MenuItem
                    className={tab === 'sales_monthly-comparison' ? 'active' : ''}
                    onClick={() => handleChange('sales_monthly-comparison')}
                  >
                    Comparativo mensal
                  </MenuItem>
                </Span>
              )}
            </CustomTab>
          )}
          {checkPermission('reports_commercial') && (
            <CustomTab
              onMouseOver={() => setShowReports(true)}
              onMouseLeave={() => setShowReports(false)}
              onBlur={() => setShowReports(false)}
              className={
                tab === 'commercial_customer-without-sale' || tab === 'commercial_export-email' ? 'active' : ''
              }
            >
              Comercial <ArrowDropDown />
              {showReports && (
                <Span>
                  <MenuItem
                    className={tab === 'commercial_export-email' ? 'active' : ''}
                    onClick={() => handleChange('commercial_export-email')}
                  >
                    Exportação de e-mail
                  </MenuItem>
                  <MenuItem
                    className={tab === 'commercial_customer-without-sale' ? 'active' : ''}
                    onClick={() => handleChange('commercial_customer-without-sale')}
                  >
                    Clientes sem venda
                  </MenuItem>

                  {checkPermission('commercial.sellout') && (
                    <MenuItem
                      className={tab === 'commercial_sellout' ? 'active' : ''}
                      onClick={() => handleChange('commercial_sellout')}
                    >
                      Análise de Sell Out
                    </MenuItem>
                  )}

                  <MenuItem
                    className={tab === 'commercial_seller_dashboard' ? 'active' : ''}
                    onClick={() => handleChange('commercial_seller_dashboard')}
                  >
                    Dashboard de vendedor
                  </MenuItem>
                </Span>
              )}
            </CustomTab>
          )}

          {checkPermission('reports_rma') && (
            <CustomTab
              onMouseOver={() => setShowRma(true)}
              onMouseLeave={() => setShowRma(false)}
              onBlur={() => setShowRma(false)}
              className={tab === 'rma_analysis' ? 'active' : ''}
            >
              RMA <ArrowDropDown />
              {showRma && (
                <Span>
                  <MenuItem
                    className={tab === 'rma_analysis' ? 'active' : ''}
                    onClick={() => handleChange('rma_analysis')}
                  >
                    Relação de RMA
                  </MenuItem>
                </Span>
              )}
            </CustomTab>
          )}

          {checkPermission('reports_reserve') && (
            <CustomTab
              onMouseOver={() => setShowReserve(true)}
              onMouseLeave={() => setShowReserve(false)}
              onBlur={() => setShowReserve(false)}
              className={tab === 'reserve_analysis' ? 'active' : ''}
            >
              Reserva <ArrowDropDown />
              {showReserve && (
                <Span>
                  <MenuItem
                    className={tab === 'reserve_analysis' ? 'active' : ''}
                    onClick={() => handleChange('reserve_analysis')}
                  >
                    Relação de reserva
                  </MenuItem>
                </Span>
              )}
            </CustomTab>
          )}
        </div>
      </Tabs>
    </>
  );
};

export default ReportsTab;
