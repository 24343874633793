import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import React, { FormEvent } from 'react';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import { FilterItemEntry } from './hooks/useFetchItemEntry';
import { H2iBuyer } from 'types/h2iBuyer';
import { Search } from '@mui/icons-material';

interface ItemEntryFilterBoxProps {
  buyers: H2iBuyer[];
  loadingBuyers: boolean;
  filter: FilterItemEntry;
  onSearch(filter: FilterItemEntry): void;
  handleChangeFilter(index: keyof FilterItemEntry, value: any);
  loading: boolean;
}

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  alignItems: 'center',
  zoom: '80%',
  display: 'grid',
  gridTemplateColumns: '200px 400px 140px 170px 1fr 1fr 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 1fr 100px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 4',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
  },
}));

const ItemEntryFilterBox: React.FC<ItemEntryFilterBoxProps> = ({
  buyers,
  loadingBuyers,
  handleChangeFilter,
  filter,
  onSearch,
  loading,
}) => {
  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    if (loading) return;

    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch handleChange={value => handleChangeFilter('branch_id', value)} branch_id={filter.branch_id} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          label="Selecione o tipo"
          select
          onChange={e => handleChangeFilter('type', e.target.value)}
          value={filter.type}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="C">Compras</MenuItem>
          <MenuItem value="T">Transferências</MenuItem>
          <MenuItem value="O">Outros</MenuItem>
        </TextField>

        <TextField
          label="Selecione o comprador"
          select
          disabled={loadingBuyers}
          onChange={e => handleChangeFilter('buyer', e.target.value)}
          value={filter.buyer}
        >
          <MenuItem value="all">Todos</MenuItem>
          {buyers.map(buyer => (
            <MenuItem key={buyer.id} value={buyer.id}>
              {buyer.nome}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          placeholder="Digite o nome ou CNPJ do fornecedor"
          label="Fornecedor/CNPJ"
          value={filter.customer}
          onChange={e => handleChangeFilter('customer', e.target.value)}
        />

        <TextField
          placeholder="Digite o nome ou código do produto"
          label="Produto/Código"
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />

        <CustomButton
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </CustomButton>
      </Filter>
    </FilterBox>
  );
};

export default ItemEntryFilterBox;
