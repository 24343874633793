import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { useSales } from './hooks/useSales';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

const Filter = styled('form')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  zoom: '90%',
  gridTemplateColumns: '180px 320px 120px 1fr 1fr 1fr 100px',
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    zoom: '80%',
    gridTemplateColumns: '180px 1fr 1fr 1fr',
  },
  '& > div': {
    height: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  '& > .search-params': {
    gap: 10,
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& button': {
      padding: 0,
    },
    [theme.breakpoints.down('lg')]: {
      gridColumn: '2 / 4',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface SalesReportFilterBoxProps {
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  loading: boolean;
  setSelectedBranch: Dispatch<SetStateAction<any>>;
}

const SalesReportFilterBox: React.FC<SalesReportFilterBoxProps> = ({
  loading,
  searchText,
  setSelectedBranch,
  handleSearchInputChange,
  handleSearchSubmit,
}) => {
  const { filter, handleChangeFilter } = useSales();

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.branchId}
          handleChange={e => handleChangeFilter('branchId', e)}
          loading={loading}
          setBranch={branch => setSelectedBranch(branch)}
        />

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>
        <TextField
          select
          fullWidth
          label="Tipo"
          value={filter.typeSale}
          onChange={e => handleChangeFilter('typeSale', e.target.value)}
        >
          <MenuItem value="T">Todas</MenuItem>
          <MenuItem value="C">Coligadas</MenuItem>
          <MenuItem value="V">Vendas</MenuItem>
        </TextField>

        <TextField
          className="madeBy"
          label="Pedido por"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.order_by}
          onChange={e => handleChangeFilter('order_by', e.target.value)}
        />

        <TextField
          className="search"
          label="Produto"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />

        <TextField
          className="customer"
          label="Cliente"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />

        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
        >
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default SalesReportFilterBox;
