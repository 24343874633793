import { format, subDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { H2iBuyer } from 'types/h2iBuyer';
import { ProductSubGroup } from 'types/product';
import { Replacement } from 'types/replacement';
import { Reserve } from 'types/reserve';

export interface FilterReplacementProps {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  product: string;
  buyer: string;
  subgroup: string;
  forecast: string;
  working_days: string;
}

export function useFetchReplacement() {
  const { h2iApi } = useApp();
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [loadingBuyers, setLoadingBuyers] = useState(false);
  const [loadingReserves, setLoadingReserves] = useState(false);
  const [reserves, setReserves] = useState<Reserve[]>([]);
  const [buyers, setBuyers] = useState<H2iBuyer[]>([]);
  const [subgroups, setSubgroups] = useState<ProductSubGroup[]>([]);
  const [replacements, setReplacements] = useState<Replacement[]>([]);
  const [filter, setFilter] = useState<FilterReplacementProps>({
    branch_id:
      user && user.branchList?.length !== 0 ? parseInt(user.branchList.length === 12 ? '0' : user.branchList[0]) : 999,
    final_date: new Date(),
    initial_date: subDays(new Date(), 7),
    product: '',
    buyer: 'all',
    subgroup: 'all',
    forecast: '30',
    working_days: '25',
  });

  useEffect(() => {
    if (!h2iApi) return;
    setLoadingSubgroups(true);
    h2iApi
      .get(`/api/subgrupo`, {
        params: {
          id_comprador: filter.buyer === 'all' ? '' : filter.buyer,
        },
      })
      .then(_response => {
        const response = _response.data.RESULT[0];

        setSubgroups(response);
      })
      .catch(err => {
        console.error(err);
        setSubgroups([]);
      })
      .finally(() => setLoadingSubgroups(false));
  }, [filter.buyer, h2iApi]);

  useEffect(() => {
    if (!h2iApi) return;
    setLoadingBuyers(true);
    h2iApi
      .get(`/api/getcompradores`)
      .then(response => {
        if (response.data.RESULT) {
          setBuyers([]);
          return;
        }
        const _buyers = response.data.compradores
          .filter(item => item.ativo === 'S')
          .sort((a: H2iBuyer, b: H2iBuyer) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });

        setBuyers(_buyers);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingBuyers(false));
  }, [h2iApi]);

  function handleChangeFilter(index: keyof FilterReplacementProps, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const onSearch = useCallback(
    (filter: FilterReplacementProps) => {
      if (!h2iApi) return;
      if (!user?.branchList) return;

      setLoading(true);
      setLoadingReserves(true);

      const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
      const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");
      const productId = parseInt(filter.product);

      const params = {
        data_ini: formattedInitialDate,
        data_fim: formattedFinalDate,
        id_comprador: filter.buyer === 'all' ? '' : filter.buyer,
        id_subgrupo: filter.subgroup === 'all' ? '' : filter.subgroup,
        forecast: filter.forecast,
        dias_uteis: filter.working_days,
        produto: isNaN(productId)
          ? filter.product
          : filter.product.length === productId.toString().length
          ? ''
          : filter.product,
        id_produto: isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : '',
        id_filial: filter.branch_id || '',
      };

      h2iApi
        .get('/api/getreservaproduto', {
          params,
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setReserves([]);
            return;
          }
          const _reserves: Reserve[] = response.data.itens.map((item: Reserve) => {
            item.formattedUnitValue = moneyFormat(item.valor_unitario);
            item.formattedTotalValue = moneyFormat(item.valor_total);
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          });

          setReserves(_reserves);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingReserves(false));

      h2iApi
        .get('/api/getrelsugestaoreposicao', { params })
        .then(response => {
          if (response.data.MESSAGE) {
            setReplacements([]);
            return;
          }
          const _replacements: Replacement[] = response.data.map((item: Replacement) => ({
            ...item,
            formattedForecastDate: item.previsao_chegada ? parsePtBRDate(item.previsao_chegada).toISOString() : '',
            formattedCusto: moneyFormat(item.custo),
            dias_estoque: Math.round(item.dias_estoque),
          }));

          setReplacements(_replacements.sort((a, b) => b.sugestao - a.sugestao));
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, user],
  );

  return {
    loading,
    replacements,
    filter,
    loadingSubgroups,
    subgroups,
    handleChangeFilter,
    onSearch,
    loadingBuyers,
    buyers,
    reserves,
    loadingReserves,
  };
}
