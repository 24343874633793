import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import React, { FormEvent } from 'react';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import { ProductSubGroup } from 'types/product';
import { FilterReplacementProps } from './hooks/useFetchReplacement';
import { H2iBuyer } from 'types/h2iBuyer';

interface AnalysisReplacementFilterBoxProps {
  subgroups: ProductSubGroup[];
  buyers: H2iBuyer[];
  loadingBuyers: boolean;
  loadingSubgroups: boolean;
  filter: FilterReplacementProps;
  onSearch(filter: FilterReplacementProps): void;
  handleChangeFilter(index: keyof FilterReplacementProps, value: any);
  loading: boolean;
}

const Filter = styled('form')(({ theme }) => ({
  gap: 15,
  zoom: '80%',
  display: 'grid',
  gridTemplateColumns: '200px 300px 170px 280px 1fr 100px 100px 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 180px 180px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
  [theme.breakpoints.down('md')]: {
    gridColumn: '2 / 4',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gridColumn: '4 / 5',
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
  },
}));

const AnalysisReplacementFilterBox: React.FC<AnalysisReplacementFilterBoxProps> = ({
  subgroups,
  loadingSubgroups,
  loadingBuyers,
  handleChangeFilter,
  filter,
  onSearch,
  loading,
  buyers,
}) => {
  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch handleChange={value => handleChangeFilter('branch_id', value)} branch_id={filter.branch_id} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          label="Selecione o comprador"
          select
          disabled={loadingBuyers}
          onChange={e => handleChangeFilter('buyer', e.target.value)}
          value={filter.buyer}
        >
          <MenuItem value="all">Todos</MenuItem>
          {buyers.map(buyer => (
            <MenuItem key={buyer.id} value={buyer.id}>
              {buyer.nome}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Selecione o subgrupo"
          select
          disabled={loadingSubgroups}
          onChange={e => handleChangeFilter('subgroup', e.target.value)}
          value={filter.subgroup}
        >
          <MenuItem value="all">Todos</MenuItem>
          {subgroups.map(subgroup => (
            <MenuItem key={subgroup.CODIGO_SUBGRUPO} value={subgroup.CODIGO_SUBGRUPO}>
              {subgroup.NOME}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          placeholder="Digite o nome ou código do produto"
          label="Produto/Código"
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />

        <TextField
          label="Forecast"
          placeholder="Digite o forecast"
          value={filter.forecast}
          onChange={e => handleChangeFilter('forecast', e.target.value)}
        />

        <TextField
          label="Dias úteis"
          placeholder="Digite os dias úteis"
          value={filter.working_days}
          onChange={e => handleChangeFilter('working_days', e.target.value)}
        />
        <CustomButton disabled={loading} variant="contained" type="submit">
          Buscar
        </CustomButton>
      </Filter>
    </FilterBox>
  );
};

export default AnalysisReplacementFilterBox;
