import React, { FormEvent, useState } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { H2iBuyer } from 'types/h2iBuyer';
import { CommercialAnalysisSubgroupFilterParams } from './hooks/useFetchCommercialAnalysisSubgroup';
import { ProductSubGroup } from 'types/product';

const Filter = styled('form')(({ theme }) => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '180px 180px 0.5fr 185px 1fr 80px 80px 100px',
  zoom: '80%',
  [theme.breakpoints.down('lg')]: {
    gap: 15,
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 5,
});

interface CommercialAnalysisSubgroupFilterBoxProps {
  buyers: H2iBuyer[];
  loadingBuyers: boolean;
  subgroups: ProductSubGroup[];
  loadingSubgroups: boolean;
  filter: CommercialAnalysisSubgroupFilterParams;
  handleChangeFilter(index: keyof CommercialAnalysisSubgroupFilterParams, value: any): void;
  handleSearchSubmit(filter: CommercialAnalysisSubgroupFilterParams, event: FormEvent<HTMLFormElement>): void;
}

const CommercialAnalysisSubgroupFilterBox: React.FC<CommercialAnalysisSubgroupFilterBoxProps> = ({
  filter,
  buyers,
  subgroups,
  loadingBuyers,
  loadingSubgroups,
  handleChangeFilter,
  handleSearchSubmit,
}) => {
  const [err, setErr] = useState(false);

  function handleValidation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErr(false);
    if (!filter.subgroupId) {
      setErr(true);
      return;
    }

    handleSearchSubmit(filter, event);
  }
  return (
    <FilterBox>
      <Filter onSubmit={handleValidation}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <TextField
          label="Selecione o comprador"
          select
          disabled={loadingBuyers}
          onChange={e => handleChangeFilter('buyerId', e.target.value)}
          value={filter.buyerId}
        >
          <MenuItem value="all">Todos</MenuItem>
          {buyers.map(buyer => (
            <MenuItem key={buyer.id} value={buyer.id}>
              {buyer.nome}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          error={err}
          helperText={err ? 'Campo obrigatório' : ''}
          label="Selecione o subgrupo"
          select
          disabled={loadingSubgroups}
          onChange={e => handleChangeFilter('subgroupId', e.target.value)}
          value={filter.subgroupId}
        >
          {subgroups.map(subgroup => (
            <MenuItem key={subgroup.CODIGO_SUBGRUPO} value={subgroup.CODIGO_SUBGRUPO}>
              {subgroup.NOME}
            </MenuItem>
          ))}
        </TextField>

        <TextField label="Tipo" select onChange={e => handleChangeFilter('type', e.target.value)} value={filter.type}>
          <MenuItem value="C">Compras e Vendas</MenuItem>
          <MenuItem value="T">Transferências</MenuItem>
          <MenuItem value="all">Todos</MenuItem>
        </TextField>

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          label="Forecast"
          placeholder="Digite o forecast"
          value={filter.forecast}
          onChange={e => handleChangeFilter('forecast', e.target.value)}
        />

        <TextField
          label="Dias úteis"
          placeholder="Digite os dias úteis"
          value={filter.working_days}
          onChange={e => handleChangeFilter('working_days', e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CommercialAnalysisSubgroupFilterBox;
