import React, { FormEvent } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import {
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  styled,
  Button,
} from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import { FaFileExcel } from 'react-icons/fa';
import SelectBranch from 'components/select-branches/SelectBranch';
import { usePermissionRules } from 'hooks/permissionRules';
import { AbcProductFilterParams } from './AbcProduct';

const Filter = styled('form')(({ theme }) => ({
  columnGap: 10,
  rowGap: 15,
  flex: 1,
  zoom: '90%',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '0.4fr 120px 1fr 170px 170px 180px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '0.4fr 120px 1fr 150px 150px 180px',
    zoom: '80%',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 100px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
  '& > .search-params': {
    columnGap: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'grid',
  gridTemplateColumns: '100px 1fr 1fr',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    '& > button': {
      maxWidth: 100,
      width: '100%',
      justifySelf: 'end',
    },
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface AbcProductFilterBoxProps {
  filter: AbcProductFilterParams;
  handleChangeFilter(index: keyof AbcProductFilterParams, value: any): void;
  handleOpenDialogDesktop(): void;
  handleOpenExcel(): void;
  searchText: string;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(event: FormEvent<HTMLFormElement>): void;
  disabled: boolean;
}

const AbcProductFilterBox: React.FC<AbcProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  searchText,
  handleSearchInputChange,
  handleSearchSubmit,
  handleOpenDialogDesktop,
  handleOpenExcel,
  disabled,
}) => {
  const { checkPermission } = usePermissionRules();

  return (
    <FilterBox>
      <Filter onSubmit={handleSearchSubmit}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <FormControl>
          <InputLabel>Tipo de venda</InputLabel>
          <Select
            fullWidth
            label="tipo de venda"
            value={filter.typeSale}
            onChange={e => handleChangeFilter('typeSale', e.target.value)}
          >
            <MenuItem value="T">Todas</MenuItem>
            <MenuItem value="C">Coligadas</MenuItem>
            <MenuItem value="V">Vendas</MenuItem>
          </Select>
        </FormControl>

        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initialDate}
            onChange={date => handleChangeFilter('initialDate', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={filter.finalDate}
            onChange={date => handleChangeFilter('finalDate', date)}
          />
        </div>

        <TextField
          label="Produto"
          placeholder="Digite o nome do produto"
          autoFocus
          value={searchText}
          onChange={e => handleSearchInputChange(e.target.value)}
        />

        <TextField
          label="Cliente"
          placeholder="Digite o nome do cliente"
          autoFocus
          value={filter.customer}
          onChange={e => handleChangeFilter('customer', e.target.value)}
        />

        <Actions>
          <Button variant="contained" type="submit" color="primary" size="small" startIcon={<Search />}>
            Buscar
          </Button>

          <Tooltip title="Outros filtros">
            <CustomIconButton onClick={handleOpenDialogDesktop} color="inherit">
              <FilterList />
            </CustomIconButton>
          </Tooltip>

          {checkPermission('general.excel_export_button') && (
            <Tooltip title="Exportar excel">
              <CustomIconButton
                disabled={disabled}
                onClick={handleOpenExcel}
                style={{ color: disabled ? '#eee' : 'green' }}
              >
                <FaFileExcel />
              </CustomIconButton>
            </Tooltip>
          )}
        </Actions>
      </Filter>
    </FilterBox>
  );
};

export default AbcProductFilterBox;
