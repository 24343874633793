import React from 'react';
import NoData from 'components/no-data/NoData';
import { styled } from '@mui/material';
import { Replacement } from 'types/replacement';
import { useFetchAnalysisReplacementDashboard } from './hooks/useFetchAnalysisReplacementDashboard';
import AnalysisReplacementDashboardTotal from './AnalysisReplacementDashboardTotal';
import AnalysisReplacementDashboardBuyer from './buyer/AnalysisReplacementDashboardBuyer';
import AnalysisReplacementDashboardBranch from './branch/AnalysisReplacementDashboardBranch';
import AnalysisReplacementDashboardSubgroup from './subgroup/AnalysisReplacementDashboardSubgroup';
import AnalysisReplacementDashboardCritical from './critical/AnalysisReplacementDashboardCritical';

interface AnalysisReplacementDashboardProps {
  replacements: Replacement[];
}

const Container = styled('div')({
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: '1fr 1fr 1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const AnalysisReplacementDashboard: React.FC<AnalysisReplacementDashboardProps> = ({ replacements }) => {
  const { groupedBranches, groupedBuyers, groupedProducts, groupedSubgroups } =
    useFetchAnalysisReplacementDashboard(replacements);

  return (
    <Container>
      <AnalysisReplacementDashboardTotal replacements={replacements} branches={groupedBranches} />

      {replacements.length === 0 ? (
        <NoData message="Dashboard não disponível" />
      ) : (
        <Content>
          <AnalysisReplacementDashboardBuyer buyers={groupedBuyers} />

          <AnalysisReplacementDashboardBranch branches={groupedBranches} />

          <AnalysisReplacementDashboardSubgroup subgroups={groupedSubgroups} />

          <AnalysisReplacementDashboardCritical products={groupedProducts.filter(item => item.dias_estoque < 15)} />
        </Content>
      )}
    </Container>
  );
};

export default AnalysisReplacementDashboard;
