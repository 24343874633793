import React, { FormEvent, useState } from 'react';
import FilterBox from 'components/filter-box/FilterBox';
import { Button, MenuItem, TextField, styled } from '@mui/material';
import SelectBranch from 'components/select-branches/SelectBranch';
import { CommercialAnalysisProductFilterParams } from './hooks/useFetchCommercialAnalysisProduct';
import { Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';

const Filter = styled('form')(({ theme }) => ({
  gap: 10,
  flex: 1,
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: '300px 200px 190px 1fr 100px 100px 100px',
  zoom: '90%',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '190px 80px 130px 1fr 80px 90px 100px',
  },
  [theme.breakpoints.down('md')]: {
    zoom: '80%',
    gap: 15,
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 5,
});

interface CommercialAnalysisProductFilterBoxProps {
  filter: CommercialAnalysisProductFilterParams;
  handleChangeFilter(index: keyof CommercialAnalysisProductFilterParams, value: any): void;
  handleSearchSubmit(filter: CommercialAnalysisProductFilterParams, event: FormEvent<HTMLFormElement>): void;
}

const CommercialAnalysisProductFilterBox: React.FC<CommercialAnalysisProductFilterBoxProps> = ({
  filter,
  handleChangeFilter,
  handleSearchSubmit,
}) => {
  const [error, setError] = useState(false);
  function handleValidation(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError(false);

    if (!filter.productId) {
      setError(true);
      return;
    }

    handleSearchSubmit(filter, e);
  }
  return (
    <FilterBox>
      <Filter onSubmit={e => handleValidation(e)}>
        <SelectBranch
          branch_id={filter.selectedBranchId}
          handleChange={e => handleChangeFilter('selectedBranchId', e)}
        />

        <TextField
          error={error}
          helperText={error ? 'Campo obrigatório' : ''}
          label="Código"
          placeholder="Digite o código do produto"
          inputMode="numeric"
          type="number"
          value={filter.productId}
          onChange={e => handleChangeFilter('productId', e.target.value)}
        />

        <TextField label="Tipo" select onChange={e => handleChangeFilter('type', e.target.value)} value={filter.type}>
          <MenuItem value="C">Compras e Vendas</MenuItem>
          <MenuItem value="T">Transferências</MenuItem>
          <MenuItem value="all">Todos</MenuItem>
        </TextField>

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          label="Forecast"
          placeholder="Digite o forecast"
          value={filter.forecast}
          onChange={e => handleChangeFilter('forecast', e.target.value)}
        />

        <TextField
          label="Dias úteis"
          placeholder="Digite os dias úteis"
          value={filter.working_days}
          onChange={e => handleChangeFilter('working_days', e.target.value)}
        />

        <Button type="submit" variant="contained" color="primary" size="small" startIcon={<Search />}>
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default CommercialAnalysisProductFilterBox;
